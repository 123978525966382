<template>
  <backoffice-container>
    <!-- General -->
    <div v-if="isLoading" class="mb-5">
      <b-spinner
        type="grow"
        small
        variant="primary"
        class="mx-auto mt-3 d-block"
      />
    </div>
    <div v-else>
      <b-card :key="widgetKey" class="px-1">
        <!-- Unshare -->
        <div v-if="form" class="mt-2 pb-2 mb-1">
          <div class="d-flex justify-content-between">
            <div>
              <h3 class="mb-0">
                {{ translatedFieldName(form.name) }}
              </h3>
              <p class="text-muted small mb-2">
                {{ $t("backoffice.communication.forms.created-by") }}
                <span class="pointer" style="text-decoration: underline">
                  <b-link class="text-muted" :to="getCreatorLocation(form)">
                    {{ form.createdBy.name }}
                    {{ form.createdBy.surname }}
                  </b-link>
                </span>
                {{ $t("backoffice.communication.forms.created-on") }}
                {{ getDateLong(form.createdAt) }}
              </p>
            </div>
            <div class="trash-icon">
              <b-badge 
                class="mr-1 px-1" 
                pill 
                :variant="types[form.type].color"
              >
                {{ types[form.type].label }}
              </b-badge>
              <b-link :to="goToPreviewForm()" target="_blank">
                <feather-icon
                  v-b-tooltip.hover.bottom
                  icon="EyeIcon"
                  :title="$t('tooltips.crud.preview')"
                  size="20"
                  class="text-primary pointer mr-1"
                />
              </b-link>
              <feather-icon
                v-b-tooltip.hover.bottom
                icon="ShareIcon"
                :title="$t('tooltips.crud.share')"
                size="20"
                class="text-primary pointer mr-1"
                @click="openShareModal"
              />
              <feather-icon
                v-b-tooltip.hover.bottom
                icon="Edit2Icon"
                :title="$t('tooltips.crud.edit')"
                size="20"
                class="text-primary pointer mr-1"
                @click="$bvModal.show('forms-bo-modal')"
              />
              <feather-icon
                v-b-tooltip.hover.bottom
                icon="Trash2Icon"
                :title="$t('tooltips.crud.delete')"
                size="20"
                class="text-primary pointer"
                @click="deleteItem(form, true)"
              />
            </div>
          </div>
          <p v-if="form.questions" class="mb-25">
            {{ $t("backoffice.communication.forms.questions") }}:
            {{ form.questions.length }}
          </p>
          <p class="mb-25">
            {{ $t("backoffice.communication.forms.is-completable") }}
            {{ $t(form.isCompletable ? 'yes' : 'no') }}
          </p>
          <p v-if="form.isCompletable" class="mb-25">
            {{ $t("backoffice.communication.forms.points-when-passed") }}:
            {{ form.pointsWhenPassed ? form.pointsWhenPassed : $t('backoffice.communication.forms.points-when-passed-default') }}
          </p>
          <p v-if="form.scoreToPass" class="mb-25">
            {{ $t("backoffice.communication.forms.score-to-pass") }}:
            {{ form.scoreToPass }}
          </p>
          <p v-if="form.maxScore" class="mb-25">
            {{ $t("backoffice.communication.forms.max-score") }}:
            {{ form.maxScore }}
          </p>
          <p v-if="form.startDate" class="mb-25">
            {{ $t("backoffice.communication.forms.start-date") }}:
            {{ getDateLong(form.startDate) }}
          </p>
          <p v-if="form.endDate" class="mb-25">
            {{ $t("backoffice.communication.forms.end-date") }}:
            {{ getDateLong(form.endDate) }}
          </p>
          <!--p class="mb-25">
            {{ $t("backoffice.communication.forms.created-at") }}:
            {{ getDateLong(form.createdAt) }}
          </p>
          <p v-if="form.updated_at" class="mb-25">
            {{ $t("backoffice.communication.forms.sent") }}:
            {{ getDateLong(form.updated_at) }}
          </-->
          <p
            v-if="form.overview"
            class="mt-2"
            v-html="translatedFieldName(form.overview)"
          />
          <p
            v-if="form.description"
            class="mt-2"
            v-html="translatedFieldName(form.description)"
          />
          <div class="d-flex justify-content-between mt-2">
            <!--p
              v-if="showQuestions"
              class="text-primary pointer mb-0"
              @click="showQuestions = false"
            >
              <feather-icon
                icon="ChevronDownIcon"
                size="20"
                class="text-primary zindex-1"
              />
              {{ $t("backoffice.communication.forms.hide-questions") }}
            </p>
            <p
              v-else
              class="text-primary mb-0 pointer"
              @click="showQuestions = true"
            >
              <feather-icon
                icon="ChevronUpIcon"
                size="20"
                class="text-primary zindex-1"
              />
              {{ $t("backoffice.communication.forms.show-questions") }}
          </p-->
            <b-button
              variant="primary"
              @click="$bvModal.show('forms-bo-send-modal')"
            >
              {{ $t("backoffice.communication.forms.send") }}...
            </b-button>
          </div>
        </div>

        <b-tabs class="mt-1 mb-2">
          <!-- Questions -->
          <b-tab
            :title="$t('backoffice.onboarding-form.questions.title')"
            active
          >
            <hr class="mt-0" />
            <div
              class="d-flex justify-content-between flex-wrap mt-1 mb-1 align-items-center"
            >
              <div />
              <div>
                <div
                  v-if="isDraggable"
                  class="d-flex justify-content-end flex-wrap w-100"
                >
                  <feather-icon
                    v-b-tooltip.hover.bottom
                    icon="SaveIcon"
                    :title="$t('header.action.save-layout')"
                    size="20"
                    class="text-primary"
                    @click="handleEditOrder()"
                  />
                  <feather-icon
                    icon="XIcon"
                    size="20"
                    class="text-primary zindex-1 ml-1"
                    @click="closeEdit"
                  />
                </div>
                <div
                  v-else
                  class="w-100 d-flex justify-content-start flex-wrap"
                >
                  <b-button
                    v-if="questions && questions.length > 1"
                    variant="primary"
                    size="sm"
                    class="text-primary d-flex align-items-center mr-50 create-item"
                    @click="openEdit"
                  >
                    <feather-icon
                      icon="Edit2Icon"
                      variant="primary"
                      size="18"
                    />
                    <p class="m-0 ml-50">
                      {{ $t("backoffice.onboarding-form.edit-order") }}
                    </p>
                  </b-button>
                  <b-button
                    variant="primary"
                    size="sm"
                    class="text-primary d-flex align-items-center create-item"
                    @click="openModal"
                  >
                    <feather-icon icon="PlusIcon" variant="primary" size="18" />
                    <p class="m-0 ml-50">
                      {{ $t("backoffice.onboarding-form.new-question") }}
                    </p>
                  </b-button>
                </div>
              </div>
            </div>
            <b-list-group
              v-if="questions && questions.length > 0"
              ref="container"
              class="w-100"
            >
              <draggable
                v-if="isDraggable"
                v-model="questions"
                :list="questions"
                :move="checkMove"
                @start="dragging = true"
                @end="dragging = false"
              >
                <transition-group>
                  <b-list-group-item
                    v-for="(question, index) in questions"
                    :key="index"
                    no-body
                    draggable
                    class="x-scroll-element grid-item"
                  >
                    <question
                      :question="question"
                      :index="index"
                      @editModal="editModal"
                      @deleteItem="deleteItem"
                    />
                  </b-list-group-item>
                </transition-group>
              </draggable>

              <b-list-group-item
                v-for="(question, index) in questions"
                v-show="!isDraggable"
                :key="index"
                no-body
                class="x-scroll-element"
              >
                <question
                  :question="question"
                  :index="index"
                  @editModal="editModal"
                  @deleteItem="deleteItem"
                />
              </b-list-group-item>
            </b-list-group>
            <!-- Container without data: Placeholder -->
            <b-row v-else class="horizontal-placeholder">
              <b-col cols="12">
                <img :src="onboardingFormPlaceholder" />
              </b-col>
              <b-col cols="12">
                <p class="text-primary">
                  {{
                    $t("available.message", {
                      itemName: $t(
                        "backoffice.onboarding-form.item-placeholder"
                      ),
                    })
                  }}
                </p>
              </b-col>
            </b-row>
          </b-tab>

          <!-- Answers -->
          <b-tab :title="$t('backoffice.faqs.answers')">
            <div class="d-flex align-items-center float-right">
              <b-button
                variant="primary"
                class="float-right ml-1"
                @click="exportCsv"
              >
                <div v-if="!isLoadingButton">
                  {{ $t("backoffice.members.export") }}
                </div>
                <b-spinner v-else small class="spinner" />
              </b-button>
            </div>
            <hr class="mt-0" />
            <form-responses
              :form-key="formKey"
              :questions="questions"
              :from-forms="true"
            />
          </b-tab>
        </b-tabs>
      </b-card>
      
      <!-- Create and Edit Modal -->
      <modal-create-question
        :question="question"
        :form="form"
        @editQuestion="editQuestion"
      />

      <!-- Unshare Modal -->
      <b-modal
        id="modal-unshare-questions-onboarding"
        v-model="isUnshareModalVisible"
        :title="
          $t('unshare.title', {
            item: $t('backoffice.onboarding-form.error-question'),
          })
        "
        size="lg"
        hide-footer
      >
        <feather-icon
          size="40"
          icon="AlertTriangleIcon"
          class="d-block mx-auto text-danger mb-1 mt-2"
        />
        <div v-if="questionDelete">
          <h5 class="mb-2 px-2 text-center">
            {{ $t("backoffice.onboarding-form.delete-text") }}
          </h5>
          <p class="text-muted text-center mb-4">
            "{{
              questionDelete.name[selectedLanguage] ||
              Object.values(questionDelete.name)[0]
            }}"
          </p>
        </div>
        <div v-if="formDelete">
          <h5 class="mb-2 px-2 text-center">
            {{
              $t("unshare.message", {
                item: $t("backoffice.onboarding-form.error-form"),
              })
            }}
          </h5>
          <p class="text-muted text-center mb-4">
            "{{
              formDelete.name[selectedLanguage] ||
              Object.values(formDelete.name)[0]
            }}"
          </p>
        </div>
        <div class="d-flex justify-content-center mb-2 flex-wrap">
          <b-button
            variant="primary"
            target="_blank"
            class="mt-25"
            @click="isUnshareModalVisible = false"
          >
            {{ $t("unshare.options.no") }}
          </b-button>
          <b-button
            variant="outline-danger"
            target="_blank"
            class="ml-md-1 mt-25"
            @click="handleRemoveItem()"
          >
            {{ $t("unshare.options.yes") }}
          </b-button>
        </div>
      </b-modal>
      <!-- Modal edit form -->
      <modal-create-form :form="form" />
      <!-- Modal send form -->
      <modal-send-form :form="form" />      
      <!-- Modal share QR -->
      <modal-share-qr :params="`forms/${form.key}`" />
    </div>
  </backoffice-container>
</template>

<script>
import BackofficeContainer from "@/backoffice/components/BackofficeContainer.vue";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import OnboardingFormPlaceholder from "@/assets/images/placeholders/light/backoffice-onboarding-form.svg";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import draggable from "vuedraggable";
import FormResponses from "@/backoffice/views/forms/components/Responses.vue";
import ModalCreateForm from "@/backoffice/views/forms/components/ModalCreateForm.vue";
import ModalSendForm from "@/backoffice/views/forms/components/ModalSendForm.vue";
import Question from "@/backoffice/views/forms/components/Question.vue";
import ModalShareQr from "@/backoffice/views/forms/components/ModalShareQr.vue";
import ModalCreateQuestion from "@/backoffice/views/forms/components/ModalCreateQuestion.vue";
import { randomString } from "@core/utils/utils";

export default {
  name: "FormDetail",
  components: {
    BackofficeContainer,
    draggable,
    FormResponses,
    ModalCreateForm,
    Question,
    ModalSendForm,
    ModalShareQr,
    ModalCreateQuestion,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: false,
      isUnshareModalVisible: false,
      selectedLanguage: this.currentLocale,
      selectedLanguageQuestions: this.currentLocale,
      options: [{}, {}],
      name: { ca: "", es: "", en: "" },
      placeholder: { ca: "", es: "", en: "" },
      customMsg: { ca: "", es: "", en: "" },
      initialQuestion: { type: "text" },
      question: {},
      questionDelete: null,
      isSending: false,
      dragging: false,
      isDraggable: false,
      actualItems: [],
      firstItems: [],
      formDelete: null,
      showQuestions: true,
      widgetKey: randomString(10),
      isLoadingButton: false
    };
  },
  computed: {
    formKey() {
      return this.$route.params.id;
    },
    types() {
      return [
        // Falta afegir el 'file' (no preparat a la vista d'onboarding auth)
        {
          text: this.$t("backoffice.onboarding-form.types.text"),
          code: "text",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.phone"),
          code: "phone",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.number"),
          code: "number",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.email"),
          code: "email",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.date"),
          code: "date",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.country"),
          code: "country",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.checkbox"),
          code: "checkbox",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.infotext"),
          code: "infotext",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.textarea"),
          code: "textarea",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.toggle"),
          code: "boolean",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.select"),
          code: "select",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.radio"),
          code: "radio",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.custom-field"),
          code: "customField",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.classifier"),
          code: "classifier",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.organization"),
          code: "organization",
        },
        {
          text: this.$t("backoffice.onboarding-form.types.society"),
          code: "society",
        },
      ];
    },
    locales() {
      return this.$store.getters.locales;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    onboardingFormPlaceholder() {
      return OnboardingFormPlaceholder;
    },
    collective() {
      return this.$store.getters.currentCollective;
    },
    questions() {
      if (this.$store.getters.formQuestions) {
        return this.$store.getters.formQuestions.unpaginated;
      }
      return [];
    },
    form() {
      if (this.$store.getters.forms) {
        return this.$store.getters.forms.unpaginated[0];
      }
      return {};
    },
    types() {
      return {
        'form': { 
          label: this.$t('backoffice.communication.forms.types.form'),
          color: 'success'
        },
        'survey': { 
          label: this.$t('backoffice.communication.forms.types.survey'),
          color: 'info'
        },
        'exam': { 
          label: this.$t('backoffice.communication.forms.types.exam'),
          color: 'warning'
        },
        'activity': { 
          label: this.$t('backoffice.communication.forms.types.activity'),
          color: 'primary'
        }
      };
    },
  },
  async created() {
    this.isLoading = true;
    await this.fetchForm();
    this.updateBreadcrumbs();
    await this.fetchQuestions();
    this.isLoading = false;
  },
  methods: {
    async exportCsv() {
      this.isLoadingButton = true;
      const response = await this.$store.dispatch("exportFiles", {
        itemType: "backoffice/forms/exportResponses",
        requestConfig: {
          formKey: this.form.key,
        },
      });
      setTimeout(this.csv(response), 10000);
      this.isLoadingButton = false;
    },
    csv(response) {
      const hiddenElement = document.createElement("a");
      hiddenElement.href = response.data.file;
      hiddenElement.target = "_blank";
      hiddenElement.download = "Members.csv";
      hiddenElement.click();
    },
    async fetchForm() {
      await this.$store.dispatch("getItems", {
        itemType: "forms",
        forceAPICall: true,
        requestConfig: { formKey: this.formKey },
        page: 1,
      });
    },
    async fetchQuestions() {
      await this.$store.dispatch("getItems", {
        itemType: "formQuestions",
        forceAPICall: true,
        requestConfig: { formKey: this.formKey },
        page: 1,
        perPage: 100,
      });
    },
    async fetchClassifiersByType() {
      await this.$store.dispatch("getClassifiersBO", {
        key: this.$route.params.id,
      });
    },
    customFieldSelect() {
      if (this.question.customField?.required) {
        this.question.rules = "required";
      }
    },
    translatedFieldName(field) {
      for (const row of Object.keys(field)) {
        if (field[row]?.length === 0 || !field[row]) {
          delete field[row];
        }
      }
      return translateTranslationTable(this.selectedLanguage, field);
    },
    getColor(type) {
      if (type === "survey") {
        return "warning";
      }
      return "success";
    },
    async onSubmit() {
      await this.$store.dispatch("createItem", {
        noSet: true,
        item: {
          itemType: "sendForm",
          orderByDate: 1,
          requestConfig: {
            formKey: this.formKey,
            customMsg: this.customMsg,
          },
        },
      });
    },
    editQuestion(key) {
      this.widgetKey = randomString(10);
      this.fetchQuestions(key);
      this.options = [{}, {}];
      this.question = {
        name: { ...this.name },
        placeholder: { ...this.placeholder },
        options: [...this.options],
        ...this.initialQuestion,
      };
    },
    async openModal() {
      this.options = [{}, {}];
      this.question = {
        name: { ...this.name },
        placeholder: { ...this.placeholder },
        options: [...this.options],
        ...this.initialQuestion,
      };
      this.$bvModal.show("modal-create-questions-onboarding");
    },
    closeModal() {
      this.$bvModal.hide("modal-create-questions-onboarding");
    },
    /* Inici ordenar preguntes */
    async checkMove(event) {
      this.actualItems = [];
      this.actualItems = [...this.questions];
      const oldIndex = event.draggedContext.index;
      const newIndex = event.draggedContext.futureIndex;
      this.actualItems.splice(oldIndex, 1);
      this.actualItems.splice(newIndex, 0, event.draggedContext.element);
    },
    async handleEditOrder() {
      this.isSending = true;
      for (const [index, item] of this.actualItems.entries()) {
        item.order = index + 1;
      }
      await this.$store.dispatch("editItem", {
        noSet: true,
        item: {
          itemType: "/formQuestions/order",
          requestConfig: {
            formKey: this.formKey,
            questions: this.actualItems,
          },
        },
      });
      this.firstItems = [];
      this.firstItems = [...this.actualItems];
      this.isDraggable = false;
      this.isSending = false;
    },
    openShareModal() {
      this.$bvModal.show("forms-bo-share-modal");
    },
    openEdit() {
      this.firstItems = [];
      this.firstItems = [...this.questions];
      this.isDraggable = true;
    },
    closeEdit() {
      this.$store.commit("SET_ITEMS", {
        type: "formQuestions",
        forceAPICall: true,
        page: 1,
        data: {
          data: this.firstItems,
          meta: {
            current_page: 1,
            total: this.firstItems.length,
          },
        },
      });
      this.isDraggable = false;
    },
    /* Fi ordenar preguntas */
    getDate(time) {
      const date = new Date(time);
      return date.toLocaleString(this.currentLocale, {
        dateStyle: "long",
        timeStyle: "short",
      });
    },
    getDateLong(time) {
      const date = new Date(time);
      return date.toLocaleString(this.currentLocale, {
        dateStyle: "full",
        timeStyle: "short",
      });
    },
    getCreatorLocation(item) {
      return {
        name: "member-details",
        params: {
          username: item.createdBy.username,
          communityId: this.$route.params.communityId,
        },
      };
    },
    goToPreviewForm() {
      return {
        name: "forms-detail",
        params: {
          id: this.formKey,
          communityId: this.$route.params.communityId,
        },
        query: {
          preview: true,
        },
      };
    },
    goToForms() {
      return {
        name: "forms-bo",
        params: {
          communityId: this.$route.params.communityId,
        },
      };
    },
    async editModal(question) {
      this.question = { ...question };
      this.options =
        question.options.length > 0 ? [...question.options] : [{}, {}];
      this.question.options = this.options;
      if (question.customFieldLink) {
        this.question.type = "customField";
        this.question.customField = this.customFields.find(
          (customField) => customField.key === question.customFieldLink.key
        );
      }
      if (question.classifierTypeLink) {
        this.question.classifier = this.classifiers.find(
          (classifierType) =>
            classifierType.key === question.classifierTypeLink.key
        );
      }
      this.$bvModal.show("modal-create-questions-onboarding");
    },
    deleteItem(item, isForm = false) {
      if (isForm) {
        this.formDelete = item;
      } else {
        this.questionDelete = item;
      }
      this.isUnshareModalVisible = true;
    },
    async handleRemoveItem() {
      try {
        if (this.questionDelete) {
          await this.$store.dispatch("unshareItem", {
            item: {
              itemType: "formQuestions",
              key: this.questionDelete.key,
            },
          });
        }
        if (this.formDelete) {
          if (this.questions.length > 0) {
            for (const question of this.questions) {
              await this.$store.dispatch("unshareItem", {
                item: { itemType: "formQuestions", key: question.key },
              });
            }
          }
          await this.$store.dispatch("unshareItem", {
            item: { itemType: "forms", key: this.formDelete.key },
          });
          this.$router.push({
            name: "forms-bo",
            params: { communityId: this.$route.params.communityId },
          });
        }
        this.isUnshareModalVisible = false;
        this.notifySuccess(this.$t("success-message.general-success-delete"));
        this.formDelete = null;
        this.questionDelete = null;
      } catch {
        this.notifyError(this.$t("error-message.general-error"));
      }
    },
    addOption(options) {
      options.push({});
    },
    deleteOption(options) {
      if (options.length > 2) {
        options.pop();
      }
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: "backoffice.communication.forms.page-title.forms-list",
          to: { name: "forms-bo" },
        },
        {
          text: this.translatedFieldName(this.form.name),
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-include"; // Bootstrap includes

.vs__dropdown-toggle {
  padding: 3.5px 0 7px !important;
  min-width: 160px;
}
.grid-item {
  box-shadow: 0px 0px 6px -2px $primary !important;
}
.share-icon {
  position: absolute;
  margin-top: -60px;
  right: 0;
  cursor: pointer;
}
</style>
