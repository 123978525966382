<template>
  <b-modal
    id="forms-bo-send-modal"
    centered
    size="lg"
  >
    <template #modal-header>
      <language-selector-header :title="$t('backoffice.communication.forms.send')" @closeModal="closeModal" @selectLanguage="(language)=>selectedLanguage = language" />
    </template>

    <b-form>
      <b-form-group>
        <label for="message" class="font-weight-bold">{{ $t('backoffice.communication.forms.custom-message') }}</label>
        <quill-editor id="message" v-model="customMsg[selectedLanguage]" />
      </b-form-group>
    </b-form>
    <p class="mt-1 mb-50 text-primary">
      <feather-icon class="mr-50" icon="AlertTriangleIcon" /> {{ $t('backoffice.communication.forms.note') }}
    </p>
    <template #modal-footer>
      <b-button variant="primary" @click="onSubmit()">
        {{ $t('backoffice.communication.forms.send') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import { quillEditor } from 'vue-quill-editor';

export default {
  name: 'ModalSendForm',
  components: {
    LanguageSelectorHeader, quillEditor,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      customMsg: { ca: '', es: '', en: '' },
      selectedLanguage: '',
    };
  },
  created() {
    this.selectedLanguage = this.locale;
  },
  methods: {
    async onSubmit() {
      try {
        await this.$store.dispatch('createItem', {
          noSet: true,
          item: {
            itemType: 'sendForm',
            requestConfig: {
              formKey: this.form.key,
              customMsg: this.customMsg,
            },
          },
        });
        this.customMsg = { ca: '', es: '', en: '' };
        this.notifySuccess(this.$t('backoffice.communication.forms.success-message'));
        this.closeModal();
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    closeModal() {
      this.$bvModal.hide('forms-bo-send-modal');
    },
  },
};
</script>

<style>

</style>
