<template>
  <b-modal
    id="forms-bo-share-modal"
    centered
    hide-footer
    :title="$t('backoffice.communication.forms.share')"
  >
    <h4 class="text-center my-1">
      {{ $t('organizations.qr.share-title') }}
    </h4>
    <img :src="link" style="width: 250px" class="d-block mx-auto small-card">
    <div class="text-center mt-2 mb-1">
      <b-button class="font-weight-bold" :variant="'primary'" @click="handleClipboardCopied()">
        <span
          class="d-flex pb-25"
        ><feather-icon icon="CopyIcon" size="18" class="d-block mx-auto mr-1" /><span class="pl-50 pt-25">{{
          $t('share.clipboard-title')
        }}</span></span>
      </b-button>
    </div>
    <b-alert :show="isCopied" variant="primary" class="text-center">
      {{ $t('share.copied-message') }}
    </b-alert>
  </b-modal>
</template>

<script>
export default {

  name: 'ShareQRModal',
  props: {
    params: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isCopied: false,
    };
  },
  computed: {
    community() {
      return this.$store.getters.currentCollective;
    },
    qrUrl() {
      const url = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}',
        this.community.subdomain || 'app',
      ).replace('{slug}', this.community.slug);
      return `${url}/${this.params}`;
    },
    link() {
      return `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${encodeURIComponent(
        this.qrUrl,
      )}&choe=UTF-8`;
    },
  },
  methods: {
    handleClipboardCopied() {
      this.isCopied = true;
      return navigator.clipboard.writeText(this.qrUrl);
    },
  },
};

</script>

<style>

</style>
